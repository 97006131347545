import * as React from "react"
import SEO from "../components/seo/Seo"
import Layout from "../components/layout/Layout"
import NavBar from "../components/navbar/NavBar";
import Tickets from "../components/tickets/Tickets";


const TicketsPage = () => {
    return (
        <Layout>
            <SEO title="Tickets" />
            <NavBar />
            <Tickets/>
        </Layout>
    )
}

export default TicketsPage
