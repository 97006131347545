import React, {useEffect} from 'react';
import Logo from "../../images/logo_22.svg";
import { navigate, Link } from "gatsby"

import './tickets.scss'

const Tickets = () => {
    const orderCompleteCallback = function() {
        console.log("Order complete!");
        navigate("/thankyou");
    };

    useEffect(() => {
      window.EBWidgets.createWidget({
        // Required
        widgetType: "checkout",
        eventId: "360362964327",
        iframeContainerId: "eventbrite-widget-container-360362964327",
        // Optional
        iframeContainerHeight: 425, // Widget height in pixels. Defaults to a minimum of 425px if not provided
        onOrderComplete: orderCompleteCallback, // Method called when an order has successfully completed
      });
    });

    return (
        <div className="tickets">
            <div className="container mt-5 mb-5">
                <div className="row">
                    <div className="col d-flex justify-content-center">
                        <img className="w-25 w-sm-100" alt="logo" src={Logo}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col d-flex justify-content-center">
                        <span className="donate-sub-title">wishes you an enlightening conference </span>
                    </div>
                </div>
                <div className="row section">
                    <div className="col d-flex justify-content-center">
                        <button className="btn btn-primary second-button mt-5">
                            <Link to="/become-donor">
                                <span className="button-text">Become a donor as a company</span>
                            </Link>
                        </button>
                    </div>
                </div>
                <div className="row eventbrite">
                    <div className="col d-flex justify-content-center">
                        <div id="eventbrite-widget-container-360362964327"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tickets;
